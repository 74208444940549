import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { ApiCustomer, ApiTraveler } from '@ibe/api';
import { Button, Col, CustomInput, Input, Label, Row } from 'reactstrap';
import { Error, Select, Success, useTranslation, Warning } from '@ibe/components';
import fallback from '../../../../Translations/newsletter.de.json';
import Keys from '../../../../Translations/generated/newsletter.de.json.keys';
import styled from '@emotion/styled';

export interface Props {
  customer?: ApiCustomer;
  onSubmit: (traveler: ApiTraveler) => Promise<string>;
}

type Option = {
  label: string;
  value: string;
};

const Sub = styled.p({
  fontSize: '12px',
  marginBottom: '1.7rem'
});

const NewsletterInput = ({
  label,
  preset,
  onChange
}: {
  label: string;
  preset: string;
  onChange: (v: string) => void;
}): JSX.Element => {
  return (
    <Input
      required={true}
      type="text"
      value={preset}
      placeholder={label}
      onChange={event => onChange(event.target.value)}
    />
  );
};

const Newsletter = observer(
  (props: Props): JSX.Element => {
    const { customer, onSubmit } = props;

    const [checked, setChecked] = useState<boolean>(false);
    const [lastNameValue, setLastNameValue] = useState<string>(customer?.lastName || '');
    const [firstNameValue, setFirstNameValue] = useState<string>(customer?.firstName || '');
    const [salutation, setSalutation] = useState<string>(customer?.salutation || '');
    const [email, setEmail] = useState<string>(customer?.communicationDetails?.email || '');
    const [response, setResponse] = useState<string>('');

    const { t } = useTranslation('newsletter', fallback);

    const handleChange = (): void => {
      setChecked(value => !value);
    };

    const salutations: Option[] = useMemo(() => {
      return [
        { value: 'female', label: t(Keys.female) },
        { value: 'male', label: t(Keys.male) }
      ];
    }, [t]);

    const submit = (): void => {
      setResponse('');
      onSubmit({
        firstName: firstNameValue,
        lastName: lastNameValue,
        salutation: t(salutation),
        communicationDetails: {
          email: email
        }
      } as ApiTraveler).then(res => setResponse(res));
    };

    return (
      <div>
        <p className="mb-4">{t(Keys.text)}</p>
        <form
          id="newsletter-form"
          action="#"
          method="post"
          hidden={(response && response === Response.ok) as boolean}
        >
          <Row>
            <Col md={3}>
              <div className="form-group">
                <Select
                  id="salutation"
                  options={salutations}
                  placeholder={t(Keys.salutation)}
                  selectedOption={salutations.find(option => option.value === salutation)}
                  onChange={(option: Option) => {
                    setSalutation(option.value);
                  }}
                  getValue={(option: Option) => option.value}
                  getLabel={(option: Option) => option.label}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="form-group">
                <NewsletterInput
                  label={t(Keys.firstname)}
                  preset={firstNameValue}
                  onChange={setFirstNameValue}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <NewsletterInput
                  label={t(Keys.lastname)}
                  preset={lastNameValue}
                  onChange={setLastNameValue}
                />
              </div>
            </Col>
            <Col md={12}>
              <div className="form-group">
                <NewsletterInput label={t(Keys.email)} preset={email} onChange={setEmail} />
              </div>
            </Col>
          </Row>
          <Label className="d-flex align-items-center" onClick={handleChange}>
            <CustomInput
              id="iso__newsletter-submit-checkbox"
              type="checkbox"
              onChange={handleChange}
              checked={checked}
            />
            <span className="pl-2">{t(Keys.checkbox)}</span>
          </Label>
          <Sub>{t(Keys.subline)}</Sub>
          <Row>
            <Col>
              <div className="form-group d-flex justify-content-end">
                <Button
                  disabled={!salutation || !firstNameValue || !lastNameValue || !email || !checked}
                  color="secondary"
                  onClick={submit}
                >
                  {t(Keys.submit)}
                </Button>
              </div>
            </Col>
          </Row>
        </form>
        {response && response === Response.ok && (
          <div className="pt-4">
            <Success message={t(Keys.response.ok)} />
          </div>
        )}
        {response && response === Response.duplicate && (
          <div className="pt-4">
            <Warning message={t(Keys.response.duplicate)} />
          </div>
        )}
        {response && response !== Response.ok && response !== Response.duplicate && (
          <div className="pt-4">
            <Error message={t(Keys.response.error)} />
          </div>
        )}
      </div>
    );
  }
);

export default Newsletter;

enum Response {
  ok = 'ok',
  duplicate = 'duplicate'
}
