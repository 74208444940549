import React, { useRef, useState } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import {
  CamperSearchWidget,
  CamperSearchParams,
  useApi,
  CamperStore,
  ContentContainer,
  CamperErrorDisplay,
  camperFillWithDefaults,
  LoadingOverlay,
  CamperSearchResults,
  sessionSearch,
  getParams,
  useConfig
} from '@ibe/components';
import { searchDetailEvent, SessionStorage } from '@ibe/services';
import CamperLoading from './CamperLoading';
import { useMount } from 'react-use';
import { camperSearchResultsKey } from '../globals';

type CollapsibleRefFns = {
  closeCollapsible: () => void;
  toggleCollapsible: () => void;
  openCollapsible: () => void;
};

const sessionStorage = new SessionStorage<CamperSearchResults>(camperSearchResultsKey);

const SearchWidgetContent = observer(function SearchWidgetContent({
  rerouteUrl,
  searchParams
}: {
  rerouteUrl?: string;
  searchParams?: Partial<CamperSearchParams>;
}) {
  const api = useApi();
  const config = useConfig();
  const store = useLocalStore(() => new CamperStore(api));
  const collapsibleRef = useRef<CollapsibleRefFns>(null);
  const [searchParamsFromProps, setSearchParamsFromProps] = useState<
    Partial<CamperSearchParams> | undefined
  >(searchParams);

  useMount(() => {
    const sessionStorageParams = sessionSearch.get();
    if (searchParams?.pickupStation != '') {
      setSearchParamsFromProps(searchParams);
    } else if (!!sessionStorageParams && Object.keys(sessionStorageParams).length > 0) {
      setSearchParamsFromProps(
        camperFillWithDefaults(
          (getParams<Partial<CamperSearchParams>>(
            ['pickupStation', 'dropOffStation', 'startDate', 'endDate', 'adults', 'children'],
            sessionStorageParams
          ) as unknown) as CamperSearchParams,
          config
        )
      );
    }
  });

  const handleSearch = async (params: Partial<CamperSearchParams>): Promise<void> => {
    store.setSearchParams(camperFillWithDefaults(params, config));
    setSearchParamsFromProps(camperFillWithDefaults(params, config));
    store.setIsLoading(true);
    await store.loadCampers();
    store.setIsLoading(false);
    if (store.hasList) {
      sessionStorage.set({
        campers: store.list,
        listOptions: store.listOptions,
        searchParams: camperFillWithDefaults(params, config)
      });
      if (rerouteUrl) {
        location.href = rerouteUrl;
      }
    } else {
      searchDetailEvent.broadcast({
        searchParams: store.searchParams,
        listOptions: store.listOptions,
        nrResultsAvailable: 0,
        nrResultsOnRequest: 0,
        nrResults: 0,
        item: store.detail
      });
    }
  };

  return (
    <LoadingOverlay
      isLoading={store.isLoading}
      positionFixed
      backgroundColor="#000"
      customSpinner={<CamperLoading />}
    >
      <ContentContainer borderRadius>
        <CamperSearchWidget
          onSearch={handleSearch}
          store={store}
          collapsibleRef={collapsibleRef}
          searchParams={
            !!searchParamsFromProps
              ? camperFillWithDefaults(searchParamsFromProps, config)
              : undefined
          }
        />
        <CamperErrorDisplay store={store} />
      </ContentContainer>
    </LoadingOverlay>
  );
});

export default SearchWidgetContent;
