import * as ReactHabitat from 'react-habitat';
import SearchWidget from '../widgets/SearchWidget';
import NewsletterWidget from '../widgets/NewsletterWidget';

class Habitat extends ReactHabitat.Bootstrapper {
  constructor() {
    super();

    const builder = new ReactHabitat.ContainerBuilder();
    builder.register(SearchWidget).as('CmbSearch');
    builder.register(NewsletterWidget).as('CmbNewsletter');
    this.setContainer(builder.build());
  }
}

export default new Habitat();
