import React, { Suspense, useContext, useState } from 'react';
import { observer } from 'mobx-react';
import { Overlay } from '@ibe/components';
import Newsletter from '../Pages/Checkout/components/newsletter/Newsletter';
import { ConfigModel } from '@ibe/services';
import { ApiTraveler, ApiTravelerFromJSON } from '@ibe/api';
import { boot, extendedConfigContext, newsletterContext } from '../Config/config';
import { CmbConfigModel } from '../Config/CmbConfigModel';
import initI18next from '../Translations/setup';
import { useMount } from 'react-use';
import CamperLoading from '../Components/CamperLoading';
import '../Theme/Styles/DER/dto/widgets/newsletterWidget.scss';
import theme from '../Theme/Styles/DER/dto/theme';
import { ThemeProvider } from '@emotion/react';

const NewsletterWidget = observer(function NewsletterWidget({
  configOverrides,
  cmbConfig
}: {
  cmbConfig: CmbConfigModel;
  configOverrides?: ConfigModel;
}) {
  const newsletterService = useContext(newsletterContext);
  const [bootstrappingFinished, setBootstrappingFinished] = useState<boolean>(false);

  useMount(async () => {
    await initI18next(cmbConfig.baseUrl, cmbConfig.version);
    await boot(configOverrides, true, cmbConfig);
    setBootstrappingFinished(true);
  });

  const onSubmit = async (traveler: ApiTraveler): Promise<string> => {
    return newsletterService.api.subscribeNewsletter(ApiTravelerFromJSON(traveler));
  };

  return bootstrappingFinished ? (
    <ThemeProvider theme={theme}>
      <Suspense
        fallback={
          <div className="transparent-overlay" style={{ height: '95px', position: 'relative' }}>
            <Overlay fullOpacity customSpinner={<CamperLoading />} />
          </div>
        }
      >
        <extendedConfigContext.Provider value={cmbConfig}>
          <Newsletter onSubmit={onSubmit} />
        </extendedConfigContext.Provider>
      </Suspense>
    </ThemeProvider>
  ) : (
    <div className="transparent-overlay" style={{ height: '95px', position: 'relative' }}>
      <Overlay fullOpacity customSpinner={<CamperLoading />} />
    </div>
  );
});

export default NewsletterWidget;
