import { LoggerFactory } from '@ibe/services';

const logger = LoggerFactory.get('TagManagerPromise');

const getTagManagerLoaded = () =>
  new Promise(resolve => {
    document.addEventListener('tmsLoaded', () => {
      logger.log('tmsLoaded');
      resolve(undefined);
    });
    setTimeout(() => {
      resolve(undefined);
    }, 5000);
  });

export default getTagManagerLoaded;
